import { CmsMeta } from "@app/http/cms/components/CmsMeta";
import { Blog } from "./Blog";
import { Expert } from "./Expert";
import { ICmsImage } from "./interfaces/ICmsImage";
import { IPosh } from "./interfaces/IPosh";
import { QnA } from "./Qna";

export interface Service {
  id?: string;
  slug: string;
  name: string;
  meta?: CmsMeta;
  type: ServiceType;
  typeSlug: ServiceTypeSlugs;
  image: ICmsImage;
  content: IPosh;
  secondaryContent: IPosh;
  experts_aoe: Expert[];
  experts_issues_treated: Expert[];
  blogs: Blog[];
  faqs: QnA[];
  abstract: string;
  totalVisits: number;
  createdAt: number;
  updatedAt: number;
}

export enum ServiceType {
  Counseling = "Counseling",
  Symptoms = "Symptoms",
  Psychiatry = "Psychiatry",
  SpeakingEngagements = "Speaking Engagements",
  TestingAndAssessment = "Testing & Assessment",
  MarriageRetreats = "Marriage Retreats",
  AlternativeTreatmentOptions = "Alternative Treatment Options",
  GroupTherapy = "Group Therapy",
  Unknown = "New",
}

export enum ServiceTypeSlugs {
  Counseling = "counseling",
  Symptoms = "symptoms",
  Psychiatry = "psychiatry",
  SpeakingEngagements = "speaking-engagements",
  TestingAndAssessment = "testing",
  MarriageRetreats = "marriage-retreats",
  AlternativeTreatmentOptions = "alternative-treatment-options",
  GroupTherapy = "group-therapy",
  Unknown = "new",
}
