import { Geolocation } from "@models/Geolocation";
import { cmsHttp } from "../base";
import GeolocationMappers from "../mappers/GeolocationMappers";

export const getGeolocations = async (): Promise<Geolocation[]> => {
  return cmsHttp
    .request(
      `
      query GetGeolocations {
        geolocations(orderBy: order_ASC) {
          id
          slug
          name
          address
          city
          state
          country
          zipcode
          content {
            html
            text
          }
          coordinate {
            latitude
            longitude
          }
          contact {
            phone
            email
            fax
          }
          image {
            url (
              transformation: {
                image: { resize: { width: 300, height: 200, fit: clip } }
              }
            )
            width
            height
          }
          createdAt
          updatedAt
        }
      }
    `
    )
    .then((response) => {
      const { geolocations } = response;
      return GeolocationMappers.mapGeolocations(geolocations);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getGeolocationBySlug = async (slug: string): Promise<Geolocation> => {
  return cmsHttp
    .request(
      `
      query GetGeolocationBySlug($slug: String!) {
        geolocation(where: {slug: $slug}) {
          id
          slug
          name
          address
          city
          state
          country
          zipcode
          content {
            html
            text
          }
          meta {
            title
            description
            noFollow
            noIndex
            ogImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
            twitterImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
          }
          imageCarousels {
            caption
            photo {
              height
              width
              url (
                transformation: {
                  image: { resize: { width: 1380, height: 920, fit: clip } }
                }
              )
            }
          }
          operatingHours {
            operatingHour
            dayName
          }
          coordinate {
            latitude
            longitude
          }
          contact {
            phone
            email
            fax
          }
          image {
            url (
              transformation: {
                image: { resize: { width: 300, height: 200, fit: clip } }
              }
            )
            width
            height
          }
          experts {
            ... on Expert {
              id
              slug
              image {
                url
                width
                height
              }
              firstName
              lastName
              officeRoles
              position
              credentials
              createdAt
              updatedAt
            }
          }
          createdAt
          updatedAt
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { geolocation } = response;
      return GeolocationMappers.mapGeolocation(geolocation);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getGeolocationBySlugForIndex = async (slug: string): Promise<Geolocation> => {
  return cmsHttp
    .request(
      `
      query GetGeolocationBySlug($slug: String!) {
        geolocation(where: {slug: $slug}) {
          slug
          name
          address
          city
          state
          country
          zipcode
          content {
            text
          }
          coordinate {
            latitude
            longitude
          }
          contact {
            phone
            email
            fax
          }
          image {
            url
            width
            height
          }
          createdAt
          updatedAt
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { geolocation } = response;
      return GeolocationMappers.mapGeolocation(geolocation);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
