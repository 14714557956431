export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";

// Exported by Vercel
export const REVALIDATE_TOKEN = process.env.REVALIDATE_TOKEN || "";
export const REVALIDATE_SECONDS = Number(process.env.REVALIDATE_SECONDS) || 3600;

// Apps
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || "https://www.heritagecounseling.net";
export const APP_TITLE = process.env.NEXT_PUBLIC_APP_TITLE || "";

// Metas
export const META_URL = process.env.NEXT_PUBLIC_META_URL || APP_URL;
export const META_TITLE = process.env.NEXT_PUBLIC_META_TITLE || APP_TITLE;
export const META_DESCRIPTION = process.env.NEXT_PUBLIC_META_DESCRIPTION || "";
export const META_TWITTER = process.env.NEXT_PUBLIC_META_TWITTER || "@";
export const META_KEYWORDS = process.env.NEXT_PUBLIC_META_KEYWORDS || "";

// API Endpoints
export const GRAPHCMS_URL = process.env.NEXT_PUBLIC_APP_GRAPHCMS_URL || "";
export const GRAPHCMS_TOKEN = process.env.GRAPHCMS_TOKEN || "";
export const GRAPHCMS_WEBHOOK_SECRET_KEY = process.env.GRAPHCMS_WEBHOOK_SECRET_KEY || "";

// Algolia
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "";
export const ALGOLIA_API_SEARCH_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_SEARCH_KEY || "";
export const ALGOLIA_API_ADMIN_KEY = process.env.ALGOLIA_API_ADMIN_KEY || "";

// Socials
export const SOCIAL_FACEBOOK = process.env.NEXT_PUBLIC_APP_SOCIAL_FACEBOOK || "";
export const SOCIAL_LINKEDIN = process.env.NEXT_PUBLIC_APP_SOCIAL_LINKEDIN || "";
export const SOCIAL_IG = process.env.NEXT_PUBLIC_APP_SOCIAL_IG || "";
export const SOCIAL_TWITTER = process.env.NEXT_PUBLIC_APP_SOCIAL_TWITTER || "";

// Google
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || "";
export const GA_GTAG_ID = process.env.NEXT_PUBLIC_GA_GTAG_ID || "";
export const GA_GTAG_CAMPAIGN = process.env.NEXT_PUBLIC_GA_GA_GTAG_CAMPAIGN || "";
export const GMAP_KEY = process.env.NEXT_PUBLIC_APP_GMAP_API_KEY || "";

// External link
export const COMPANY_PHONE_NUMBER = process.env.NEXT_PUBLIC_APP_PHONE_NUMBER || "";
export const CLIENT_PORTAL_URL = process.env.NEXT_PUBLIC_APP_CLIENT_PORTAL_URL || "";

// ATS
export const ATS_IFRAME_URL = process.env.NEXT_PUBLIC_ATS_WIDGET_IFRAME_URL || "";

// Client Secure
export const CLIENTSECURE_URL = process.env.CLIENTSECURE_BASE_URL || "";
export const CLIENTSECURE_SIGNIN_URI = process.env.CLIENTSECURE_SIGNIN_URI || "";

// Query methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
