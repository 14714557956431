import { APP_URL, SOCIAL_FACEBOOK, SOCIAL_LINKEDIN, SOCIAL_IG, SOCIAL_TWITTER } from "@app/utilities/constants";

export const OrganizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Heritage",
  url: APP_URL,
  logo: [APP_URL, "static/images/logo-full.png"].join("/"),
  sameAs: [SOCIAL_FACEBOOK, SOCIAL_LINKEDIN, SOCIAL_IG, SOCIAL_TWITTER].filter(Boolean),
};
