import { Headline } from "@models/Headline";
import { cmsHttp } from "../base";
import HeadlineMappers from "../mappers/HeadlineMappers";

export const getHeadlines = async (): Promise<Headline[]> => {
  return cmsHttp
    .request(
      `
      query GetHeadlines {
        headlines {
          id,
          text {
            html,
            text
          }
        }
      }
    `
    )
    .then((response) => {
      const { headlines } = response;
      return HeadlineMappers.mapHeadlines(headlines);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};
