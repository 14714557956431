import { CmsButtonComp } from "@app/http/cms/components/CmsButtonComp";
import { CmsCarousel } from "@app/http/cms/components/CmsCarousel";
import { CmsContainerView } from "@app/http/cms/components/CmsContainerView";
import { CmsCustomMarkup } from "@app/http/cms/components/CmsCustomMarkup";
import { CmsImageCarouselComp } from "@app/http/cms/components/CmsImageCarouselComp";
import { CmsMeta } from "@app/http/cms/components/CmsMeta";
import { CmsPhotoColumnView } from "@app/http/cms/components/CmsPhotoColumnView";
import { CmsRichTextComp } from "@app/http/cms/components/CmsRichTextComp";
import { CmsComponentBase } from "@app/http/cms/components/ComponentBase";
import { CustomUriComponent } from "@models/CustomUri";
import Utils from "../utils";

export const CmsComponentMappers = {
  mapCmsButton: (payload: any): CmsButtonComp => {
    return {
      __typename: payload?.__typename || "",
      href: payload?.href || "",
      label: payload?.label || "",
    };
  },
  mapCmsRichText: (payload: any): CmsRichTextComp => {
    return {
      __typename: payload?.__typename || "",
      text: Utils.mapPosh(payload?.text),
    };
  },
  mapCmsCustomMarkup: (payload: any): CmsCustomMarkup => {
    return {
      __typename: payload?.__typename || "",
      html: payload?.html || "",
    };
  },
  mapCmsMeta: (payload: any): CmsMeta => {
    return {
      title: payload?.title || "",
      description: payload?.description || "",
      noIndex: !!payload?.noIndex,
      noFollow: !!payload?.noFollow,
      ogImage: Utils.mapCmsImage(payload?.ogImage),
      twitterImage: Utils.mapCmsImage(payload?.twitterImage),
      __typename: "Meta",
    };
  },
  mapCmsContainerView: (payload: any, mapCustomUriComponent: (payload: CmsComponentBase) => CustomUriComponent): CmsContainerView => {
    return {
      __typename: payload?.__typename || "",
      fluid: !!payload?.fluid,
      content: ((payload?.content || []) as CmsComponentBase[]).map((elem) => mapCustomUriComponent(elem)).filter((elem) => !!elem.__typename),
    };
  },
  mapCmsColumnView: (payload: any, mapCustomUriComponent: (payload: CmsComponentBase) => CustomUriComponent): CmsPhotoColumnView => {
    return {
      __typename: payload?.__typename || "",
      rtl: !!payload?.rtl,
      image: Utils.mapCmsImage(payload?.image),
      content: ((payload?.content || []) as CmsComponentBase[]).map((elem) => mapCustomUriComponent(elem)).filter((elem) => !!elem.__typename),
    };
  },
  mapCmsImageCarousel: (payload: any): CmsImageCarouselComp => {
    const carouselItems = (payload?.carouselItems || []) as CmsCarousel[];

    return {
      __typename: payload?.__typename || "",
      carouselItems: carouselItems.filter((item) => {
        return !!item?.photo;
      }),
    };
  },
};
