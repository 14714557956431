import { canUseDOM } from "./helpers";

type IGAEventName = "HEADER_SEARCH" | "SEARCH_PAGE";

type GAEventArgs = {
  category: string;
  action: string;
  label: string;
};

/**
 * Pre-defined events Google Analytic event
 * @returns EventArgs
 * @link https://github.com/react-ga/react-ga#reactgaeventargs
 */
export const GAEvents: { [name in IGAEventName]: GAEventArgs } = {
  HEADER_SEARCH: {
    category: "Search",
    action: "Search Header",
    label: "",
  },
  SEARCH_PAGE: {
    category: "Search",
    action: "Search Page",
    label: "",
  },
};

/**
 * Sends either a beacon, xhr or image for Google Analytic
 * @param gaEvent Google analytic event model
 * @param label Override `label` at the supplied `gaEvent`
 * @returns void
 */
export function sendEvent(gaEvent: GAEventArgs, label?: string) {
  if (canUseDOM && Array.isArray(window?.dataLayer)) {
    const evt = Object.assign({}, gaEvent);

    if (label) {
      evt.label = label;
    }

    window.dataLayer.push({
      event: "customEvent",
      ...evt,
    });
  }
  return;
}
