import { CustomUri, CustomUriComponent } from "@models/CustomUri";
import { CmsComponentBase } from "../cms/components/ComponentBase";
import { CmsComponentMappers } from "./components/CmsComponentMappers";

const CustomUriMappers = {
  mapCustomUri: (payload: any): CustomUri => {
    const customUriModel: CustomUri = {
      uri: (payload?.uri || "").trim(),
      meta: CmsComponentMappers.mapCmsMeta(payload?.meta),
      components: CustomUriMappers.mapCustomUriComponents(payload?.components || []),
    };

    return customUriModel;
  },
  mapCustomUris: (payload: any): CustomUri[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const customUris: CustomUri[] = payload.map((elem) => CustomUriMappers.mapCustomUri(elem));
      return customUris.filter((elem) => !!elem.uri);
    }

    return [];
  },
  mapCustomUriComponent: (payload: CmsComponentBase): CustomUriComponent => {
    switch (payload?.__typename) {
      case "CustomMarkup":
        return CmsComponentMappers.mapCmsCustomMarkup(payload);
      case "ButtonComp":
        return CmsComponentMappers.mapCmsButton(payload);
      case "RichTextComp":
        return CmsComponentMappers.mapCmsRichText(payload);
      case "ContainerView":
        return CmsComponentMappers.mapCmsContainerView(payload, CustomUriMappers.mapCustomUriComponent);
      case "PhotoColumnView":
        return CmsComponentMappers.mapCmsColumnView(payload, CustomUriMappers.mapCustomUriComponent);
      case "ImageCarouselComp":
        return CmsComponentMappers.mapCmsImageCarousel(payload);
      default:
        return payload as CustomUriComponent;
    }
  },
  mapCustomUriComponents: (payload: CmsComponentBase[]): CustomUriComponent[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const customUriComponents: CustomUriComponent[] = payload.map((elem) => CustomUriMappers.mapCustomUriComponent(elem));
      return customUriComponents.filter((elem) => !!elem.__typename);
    }

    return [];
  },
};

export default CustomUriMappers;
