import { Contact } from "@models/Contact";

const ContactMappers = {
  mapContact: (payload: any): Contact => {
    return {
      id: payload?.id || "",
      phone: payload?.phone || "",
      fax: payload?.fax || "",
      email: payload?.email || "",
    } as Contact;
  },
};

export default ContactMappers;
