import { QnA } from "@models/Qna";
import { cmsHttp } from "../base";
import FaqMappers from "../mappers/FaqMappers";

export const getFeaturedFaqs = async (): Promise<QnA[]> => {
  return cmsHttp
    .request(
      `
      query GetFaqs($featured: Boolean!) {
        faqs(where: {featured: $featured}) {
          id
          slug
          question
          featured
          answer {
            html
          }
        }
      }
    `,
      {
        featured: true,
      }
    )
    .then((response) => {
      const { faqs } = response;
      return FaqMappers.mapQnas(faqs);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getFaqs = async (): Promise<QnA[]> => {
  return cmsHttp
    .request(
      `
      query GetFaqs($showList: Boolean!) {
        faqs(orderBy: order_ASC, where: {showList: $showList}) {
          id
          slug
          question
          featured
          answer {
            html
          }
        }
      }
    `,
      {
        showList: true,
      }
    )
    .then((response) => {
      const { faqs } = response;
      return FaqMappers.mapQnas(faqs);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getFaqBySlug = async (slug: string): Promise<QnA> => {
  return cmsHttp
    .request(
      `
      query GetFaqBySlug($slug: String!) {
        faq(where: {slug: $slug}) {
          slug
          question
          featured
          meta {
            title
            description
            noFollow
            noIndex
            ogImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
            twitterImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
          }
          answer {
            html
            text
          }
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { faq } = response;
      return FaqMappers.mapQna(faq);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getFaqBySlugForIndex = async (slug: string): Promise<QnA> => {
  return cmsHttp
    .request(
      `
      query GetFaqBySlug($slug: String!) {
        faq(where: {slug: $slug}) {
          slug
          question
          featured
          answer {
            text
          }
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { faq } = response;
      return FaqMappers.mapQna(faq);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
