import React from "react";
import Link from "next/link";
import logo_full from "@assets/images/logo-full.png";

const AmpHeader: React.FC = () => {
  return (
    <>
      <header className="header-comp">
        <Link href="/">
          <a className="logo-link">
            <amp-img alt="A view of the sea" src={logo_full.src} width={148} height={69.8} layout="fixed"></amp-img>
          </a>
        </Link>
        <div role="button" aria-label="open sidebar" on="tap:header-sidebar.toggle" tabIndex={0} className="menu-button">
          ☰
        </div>
      </header>
      <amp-sidebar id="header-sidebar" className="header-sidebar" layout="nodisplay" side="right">
        <div className="menu-close">
          <div role="button" aria-label="close sidebar" on="tap:header-sidebar.toggle" tabIndex={0} className="menu-close-button">
            ✕
          </div>
        </div>
        <nav className="menu-nav">
          <ul className="menu-list">
            <li className="">
              <Link href="/">
                <a className="">Home</a>
              </Link>
            </li>
            <li className="">
              <Link href="/about">
                <a className="">About</a>
              </Link>
            </li>
            <li className="">
              <Link href="/services">
                <a className="">Services</a>
              </Link>
            </li>
            <li className="">
              <Link href="/teams">
                <a className="">Teams</a>
              </Link>
            </li>
            <li className="">
              <Link href="/blogs">
                <a className="">Blogs</a>
              </Link>
            </li>
            <li className="">
              <Link href="/faqs">
                <a className="">FAQ</a>
              </Link>
            </li>
            <li className="">
              <Link href="/locations">
                <a className="">Locations</a>
              </Link>
            </li>
            <li className="">
              <Link href="/careers">
                <a className="">Careers</a>
              </Link>
            </li>
          </ul>
          <ul className="menu-list">
            <li className="">
              <Link href="/portal">
                <a className="">Access Patient Portal</a>
              </Link>
            </li>
            <li className="">
              <a href="tel:+12143632345" className="">
                Request an appointment
              </a>
            </li>
          </ul>
        </nav>
      </amp-sidebar>
    </>
  );
};

export default AmpHeader;
