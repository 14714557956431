import { ICmsImage } from "@models/interfaces/ICmsImage";
import { ICoordinate } from "@models/interfaces/ICoordinate";
import { IPosh } from "@models/interfaces/IPosh";
import noImage from "@assets/images/utils/no-image.png";
import { OfficeRole } from "@models/Expert";

const Utils = {
  mapPosh: (payload: any): IPosh => {
    const result = {
      text: payload?.text || "",
      html: payload?.html || "",
    } as IPosh;

    // Remove graphcms generated newline in 'text'
    if (result.text) {
      result.text = result.text.replace(/(\\n)+/g, " ");
    }

    if (result.html) {
      // Replace '<p>---</p>' with '<hr />'
      result.html = result.html.replace(/<(p)>\s*([-]{3,})\s*<\/\1>/gi, "<hr />");
    }

    return result;
  },
  mapCoordinate: (payload: any): ICoordinate => {
    const result = {
      latitude: payload?.latitude || 0,
      longitude: payload?.longitude || 0,
      distance: payload?.distance || null,
    } as ICoordinate;

    return result;
  },
  mapCmsImage: (payload: any): ICmsImage => {
    const result = {
      src: payload?.url || noImage.src,
      url: payload?.url || noImage.src,
      width: payload?.width || noImage.width,
      height: payload?.height || noImage.height,
    } as ICmsImage;

    return result;
  },
  mapOfficeRoles: (payload: any): OfficeRole[] => {
    const result = [];

    if (Array.isArray(payload)) {
      for (let i = 0, len = payload?.length; i < len; i++) {
        const officeRole = payload[i];
        if (OfficeRole[officeRole]) {
          result.push(OfficeRole[officeRole]);
        }
      }
    }

    return result;
  },
};

export default Utils;
