import { CmsMeta } from "@app/http/cms/components/CmsMeta";
import { Blog } from "./Blog";
import { Contact } from "./Contact";
import { Geolocation } from "./Geolocation";
import { ICmsImage } from "./interfaces/ICmsImage";
import { IPosh } from "./interfaces/IPosh";
import { Service } from "./Service";

export interface Expert {
  id?: string;
  slug: string;
  image: ICmsImage;
  meta?: CmsMeta;
  firstName: string;
  lastName: string;
  description?: IPosh;
  bio?: IPosh;
  officeRoles: OfficeRole[];
  position?: string;
  credentials: string[];
  affiliations: string[];
  contact: Contact;
  area_of_expertises: Service[];
  issues_treated: Service[];
  linkedinUrl?: string;
  blogs?: Blog[];
  geolocations?: Geolocation[];
  createdAt: number;
  updatedAt: number;

  schema?: { [key: string]: any };
}

export enum OfficeRole {
  Administrative = <any>"Administrative",
  BoardDirector = <any>"Board Director",
  ClinicalStaff = <any>"Clinical Staff",
}
