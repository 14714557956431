import { Headline } from "@models/Headline";
import Utils from "./utils";

const HeadlineMappers = {
  mapHeadline: (payload: any): Headline => {
    return {
      id: payload?.id || "",
      text: Utils.mapPosh(payload?.text),
    } as Headline;
  },
  mapHeadlines: (payload: any): Headline[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const headlines: Headline[] = payload.map((elem) => HeadlineMappers.mapHeadline(elem));
      return headlines.filter((elem) => !!elem.text);
    }

    return [];
  },
};

export default HeadlineMappers;
