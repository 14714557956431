import { APP_URL } from "@app/utilities/constants";
import { Expert } from "@models/Expert";
import BlogMappers from "./BlogMappers";
import ContactMappers from "./ContactMappers";
import GeolocationMappers from "./GeolocationMappers";
import ServiceMappers from "./ServiceMappers";
import Utils from "./utils";

const ExpertMappers = {
  mapExpert: (payload: any): Expert => {
    const expertModel: Expert = {
      id: payload?.id || "",
      slug: payload?.slug || "",
      image: Utils.mapCmsImage(payload?.image),
      meta: payload?.meta || null,
      firstName: payload?.firstName || "",
      lastName: payload?.lastName || "",
      description: Utils.mapPosh(payload?.description),
      bio: Utils.mapPosh(payload?.bio),
      officeRoles: Utils.mapOfficeRoles(payload?.officeRoles),
      position: payload?.position || "",
      credentials: payload?.credentials || [],
      affiliations: payload?.affiliations || [],
      contact: ContactMappers.mapContact(payload?.contact),
      area_of_expertises: ServiceMappers.mapServices(payload?.area_of_expertises),
      issues_treated: ServiceMappers.mapServices(payload?.issues_treated),
      linkedinUrl: payload?.linkedinUrl || "",
      blogs: BlogMappers.mapBlogs(payload?.blogs || []),
      geolocations: GeolocationMappers.mapGeolocations(payload?.geolocations || []),
      createdAt: Date.parse(payload?.createdAt) || Date.now(),
      updatedAt: Date.parse(payload?.updatedAt) || Date.now(),
    };

    /** Creates schema */
    expertModel.schema = {
      "@context": "https://schema.org",
      "@type": "Person",
      email: expertModel.contact.email,
      image: expertModel.image.src,
      jobTitle: expertModel.position,
      telephone: expertModel.contact.phone,
      name: [expertModel.firstName, expertModel.lastName].filter(Boolean).join(" "),
      url: [APP_URL, "team", expertModel.slug].join("/"),
    };

    return expertModel;
  },
  mapExperts: (payload: any): Expert[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const experts: Expert[] = payload.map((elem) => ExpertMappers.mapExpert(elem));
      return experts.filter((expert) => !!expert.slug);
    }

    return [];
  },
};

export default ExpertMappers;
