import { Service, ServiceType, ServiceTypeSlugs } from "@models/Service";
import BlogMappers from "./BlogMappers";
import ExpertMappers from "./ExpertMappers";
import FaqMappers from "./FaqMappers";
import Utils from "./utils";

const ServiceMappers = {
  mapService: (payload: any): Service => {
    return {
      id: payload?.id || "",
      slug: payload?.slug || "",
      type: ServiceType[payload?.type] || ServiceType.Unknown,
      typeSlug: ServiceTypeSlugs[payload?.type] || ServiceTypeSlugs.Unknown,
      name: payload?.name || "",
      meta: payload?.meta || null,
      image: Utils.mapCmsImage(payload?.image),
      content: Utils.mapPosh(payload?.content),
      secondaryContent: Utils.mapPosh(payload?.secondaryContent),
      totalVisits: payload?.totalVisits || 0,
      experts_aoe: ExpertMappers.mapExperts(payload?.experts_aoe),
      experts_issues_treated: ExpertMappers.mapExperts(payload?.experts_issues_treated),
      blogs: BlogMappers.mapBlogs(payload?.blogs),
      faqs: FaqMappers.mapQnas(payload?.faqs) || [],
      abstract: payload?.abstract || "",
      createdAt: Date.parse(payload?.createdAt) || Date.now(),
      updatedAt: Date.parse(payload?.updatedAt) || Date.now(),
    } as Service;
  },
  mapServices: (payload: any): Service[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const services: Service[] = payload.map((elem) => ServiceMappers.mapService(elem));
      return services;
    }

    return [];
  },
};

export default ServiceMappers;
