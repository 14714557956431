import { Service } from "@models/Service";
import { Geolocation } from "@models/Geolocation";
import { cmsHttp } from "../base";
import ServiceMappers from "../mappers/ServiceMappers";
import GeolocationMappers from "../mappers/GeolocationMappers";
import { Expert, OfficeRole } from "@models/Expert";

type TFilterGeolocation = Pick<Geolocation, "id" | "slug" | "name">;
type TFilterService = Pick<Service, "id" | "slug" | "type" | "name">;

export type IExpertFiltersResponse = {
  roles: string[];
  geolocations: TFilterGeolocation[];
  areaOfExpertises: TFilterService[];
  issuesTreated: TFilterService[];
};

const getRoleFilters = async (experts: Expert[] = []): Promise<string[]> => {
  if (experts && experts.length) {
    const roles: OfficeRole[] = [];

    for (let i = 0, len = experts.length; i < len; i++) {
      const { officeRoles } = experts[i];
      roles.push(...officeRoles);
    }

    const result = [...new Set(roles)].map((elem) => OfficeRole[elem]);

    // Sort the result by name.
    return Promise.resolve(result.sort((a, b) => a.localeCompare(b)));
  }

  return cmsHttp
    .request(
      `
      query getOfficeRoles {
        experts {
          officeRoles
        }
      }
    `
    )
    .then((response) => {
      const { experts } = response,
        roles = [];

      for (let i = 0, len = experts.length; i < len; i++) {
        const { officeRoles } = experts[i];
        roles.push(...officeRoles);
      }

      const result = [...new Set(roles)];

      // Sort the result by name.
      return result.sort((a, b) => a.localeCompare(b));
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getAreaOfExpertiseFilters = async (experts: Expert[] = []): Promise<TFilterService[]> => {
  // Grab all the AOE services from experts params.
  if (experts && experts.length) {
    const areasOfExpertises: Service[] = [];

    for (let i = 0, len = experts.length; i < len; i++) {
      const { area_of_expertises } = experts[i];
      areasOfExpertises.push(...area_of_expertises);
    }

    const result: TFilterService[] = [],
      resultIds: string[] = [];

    for (let i = 0, len = areasOfExpertises.length; i < len; i++) {
      const aoeService = areasOfExpertises[i],
        { id: serviceId, slug, type, name } = aoeService;

      if (resultIds.indexOf(serviceId) === -1) {
        result.push({ id: serviceId, slug, type, name });
        resultIds.push(serviceId);
      }
    }

    // Sort the result by name.
    return Promise.resolve(result.sort((a, b) => a.name.localeCompare(b.name)));
  }

  // Grab all the AOE services from the CMS.
  const where = ['{experts_aoe_some: {id_not: ""}}'];

  return cmsHttp
    .request(
      `
      query aoeFilters {
        services(orderBy: name_ASC, where: {AND: [${where.join(", ")}]}) {
          id
          slug
          type
          name
          experts_aoe {
            id
          }
          experts_issues_treated {
            id
          }
        }
      }
    `
    )
    .then((response) => {
      const { services } = response;
      const aoeServices = (services || []).filter((elem) => elem?.experts_aoe?.length > 0);
      return ServiceMappers.mapServices(aoeServices);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

const getIssueFilters = async (experts: Expert[] = []): Promise<TFilterService[]> => {
  // Grab all the Issues treated services from experts params.
  if (experts && experts.length) {
    const treatedIssues: Service[] = [];

    for (let i = 0, len = experts.length; i < len; i++) {
      const { issues_treated } = experts[i];
      treatedIssues.push(...issues_treated);
    }

    const result: TFilterService[] = [],
      resultIds: string[] = [];

    for (let i = 0, len = treatedIssues.length; i < len; i++) {
      const aoeService = treatedIssues[i],
        { id: serviceId, slug, type, name } = aoeService;

      if (resultIds.indexOf(serviceId) === -1) {
        result.push({ id: serviceId, slug, type, name });
        resultIds.push(serviceId);
      }
    }

    // Sort the result by name.
    return Promise.resolve(result.sort((a, b) => a.name.localeCompare(b.name)));
  }

  // Grab all the Issues treated services from the CMS.
  const where = ['{experts_issues_treated_some: {id_not: ""}}'];

  return cmsHttp
    .request(
      `
    query issueFilters {
      services(orderBy: name_ASC, where: {AND: [${where.join(", ")}]}) {
        id
        slug
        type
        name
        experts_aoe {
          id
        }
        experts_issues_treated {
          id
        }
      }
    }
  `
    )
    .then((response) => {
      const { services } = response;
      const issuesTreatedServices = (services || []).filter((elem) => elem?.experts_issues_treated?.length > 0);
      return ServiceMappers.mapServices(issuesTreatedServices);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

const getGeolocationFilters = async (experts: Expert[] = []): Promise<TFilterGeolocation[]> => {
  // Grab all the geolocation services from experts params.
  if (experts && experts.length) {
    const locations: Geolocation[] = [];

    for (let i = 0, len = experts.length; i < len; i++) {
      const { geolocations } = experts[i];
      locations.push(...geolocations);
    }

    const result: TFilterGeolocation[] = [],
      resultIds: string[] = [];

    for (let i = 0, len = locations.length; i < len; i++) {
      const location = locations[i],
        { id: locationId, slug, name } = location;

      if (resultIds.indexOf(locationId) === -1) {
        result.push({ id: locationId, slug, name });
        resultIds.push(locationId);
      }
    }

    // Sort the result by name.
    return Promise.resolve(result.sort((a, b) => a.name.localeCompare(b.name)));
  }

  // Grab all the geolocation services from the CMS.
  const where = [];

  return cmsHttp
    .request(
      `
    query geolocationFilters {
      geolocations(orderBy: name_ASC, where: {AND: [${where.join(", ")}]}) {
        id
        slug
        name
        experts {
          __typename
        }
      }
    }
  `
    )
    .then((response) => {
      const { geolocations } = response;
      return GeolocationMappers.mapGeolocations(geolocations);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const getExpertsFilters = async (experts: Expert[] = []): Promise<IExpertFiltersResponse> => {
  const [roleFilters, geolocationFilters, issuesFilters, areaOfExpertiseFilters] = await Promise.all([getRoleFilters(experts), getGeolocationFilters(experts), getIssueFilters(experts), getAreaOfExpertiseFilters(experts)]);

  return {
    roles: roleFilters,
    geolocations: geolocationFilters,
    areaOfExpertises: areaOfExpertiseFilters,
    issuesTreated: issuesFilters,
  } as unknown as IExpertFiltersResponse;
};
