import { APP_URL } from "@app/utilities/constants";
import { convertTime12to24 } from "@app/utilities/helpers";
import { Geolocation } from "@models/Geolocation";
import { CmsCarousel } from "../cms/components/CmsCarousel";
import { CmsOfficeHour } from "../cms/components/CmsOfficeHour";
import ContactMappers from "./ContactMappers";
import ExpertMappers from "./ExpertMappers";
import Utils from "./utils";

const GeolocationMappers = {
  mapGeolocation: (payload: any): Geolocation => {
    const geolocationModel: Geolocation = {
      id: payload?.id || "",
      slug: payload?.slug || "",
      image: Utils.mapCmsImage(payload?.image),
      name: payload?.name || "",
      address: (payload?.address || "")
        .split(/\n/g)
        .map((v) => v.trim())
        .filter(Boolean),
      city: payload?.city || "",
      state: payload?.state || "",
      country: payload?.country || "",
      zip: payload?.zipcode || "",
      content: Utils.mapPosh(payload?.content),
      meta: payload?.meta || null,
      operatingHours: (payload?.operatingHours || []) as CmsOfficeHour[],
      carousels: (payload?.imageCarousels || []) as CmsCarousel[],
      coordinate: Utils.mapCoordinate(payload?.coordinate),
      contact: ContactMappers.mapContact(payload?.contact),
      experts: ExpertMappers.mapExperts(payload?.experts),
      createdAt: Date.parse(payload?.createdAt) || Date.now(),
      updatedAt: Date.parse(payload?.updatedAt) || Date.now(),
    };

    /** Creates OpeningHoursSpecifications */
    const openingHoursGroup: { [key: string]: string[] } = {};

    for (let i = 0, len = geolocationModel.operatingHours?.length; i < len; i++) {
      const schedule = geolocationModel.operatingHours[i];

      if (schedule.operatingHour.indexOf(" - ") >= 0) {
        // Schedule is not "Closed" and has specific operatingHour (e.g. "10:00am - 07:00pm").
        const operatingHours24 = schedule.operatingHour.split(" - ").map((hour) => convertTime12to24(hour));
        const openClosedTime24 = operatingHours24.join(" - ");

        if (!Object.prototype.hasOwnProperty.call(openingHoursGroup, openClosedTime24)) {
          openingHoursGroup[openClosedTime24] = [];
        }

        let ensuredDay = schedule.dayName.trim().toLowerCase();
        ensuredDay = ensuredDay.charAt(0).toUpperCase() + ensuredDay.slice(1);
        openingHoursGroup[openClosedTime24].push(ensuredDay);
      }
    }

    /** Creates schema */
    geolocationModel.schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: geolocationModel.name,
      url: APP_URL,
      email: geolocationModel.contact?.email,
      telephone: geolocationModel.contact?.phone,
      image: geolocationModel?.image.src,
      address: {
        "@type": "PostalAddress",
        streetAddress: geolocationModel.address.join(" "),
        addressLocality: geolocationModel.city,
        addressRegion: geolocationModel.state,
        postalCode: geolocationModel.zip,
        addressCountry: geolocationModel.country,
      },
      location: {
        "@type": "Place",
        geo: {
          "@type": "GeoCoordinates",
          latitude: geolocationModel.coordinate?.latitude,
          longitude: geolocationModel.coordinate?.longitude,
        },
      },
    };

    const openingHoursGroupKeys = Object.keys(openingHoursGroup),
      openingHoursGroupLength = openingHoursGroupKeys?.length;

    if (openingHoursGroupLength >= 0) {
      geolocationModel.schema.hoursAvailable = openingHoursGroupKeys.map((openClosedTime24) => {
        const [opens, closes] = openClosedTime24.split(" - ");
        const daysArr = openingHoursGroup[openClosedTime24];

        return {
          "@type": "OpeningHoursSpecification",
          opens,
          closes,
          dayOfWeek: daysArr.map((name) => {
            return {
              "@type": "DayOfWeek",
              "@id": "http://schema.org/" + name,
              name,
            };
          }),
        };
      });
    }

    return geolocationModel;
  },
  mapGeolocations: (payload: any): Geolocation[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const geolocations: Geolocation[] = payload.map((elem) => GeolocationMappers.mapGeolocation(elem));
      return geolocations;
    }

    return [];
  },
};

export default GeolocationMappers;
