import axios, { AxiosRequestConfig } from "axios";
import { GraphQLClient } from "graphql-request";
import { HttpError } from "@models/HttpError";
import { CLIENTSECURE_URL, GRAPHCMS_TOKEN, GRAPHCMS_URL, IS_PROD } from "@app/utilities/constants";

const baseServer = axios.create({
  baseURL: "/api",
  timeout: 1e4,
});

const clientSecureServer = axios.create({
  baseURL: CLIENTSECURE_URL,
  timeout: 1e4,
});

export const cmsHttp = new GraphQLClient(GRAPHCMS_URL, {
  headers: {
    authorization: `Bearer ${GRAPHCMS_TOKEN}`,
  },
});

export const serverHttp = (config: AxiosRequestConfig) => {
  const cancelTokenSource = axios.CancelToken.source(),
    requestConfig: AxiosRequestConfig = {
      cancelToken: cancelTokenSource.token,
      ...config,
    },
    request = baseServer(requestConfig).catch((reason: HttpError) => {
      if (!IS_PROD) {
        console.log({ reason });
      }

      throw reason;
    });

  return {
    cancel: cancelTokenSource,
    request,
  };
};

export const clientSecureHttp = (config: AxiosRequestConfig) => {
  const cancelTokenSource = axios.CancelToken.source(),
    requestConfig: AxiosRequestConfig = {
      cancelToken: cancelTokenSource.token,
      headers: {
        accept: "application/vnd.api+json",
        "api-version": "2019-01-17",
        "content-type": "application/vnd.api+json",
        "application-build-version": "0.0.0",
        "application-platform": "web",
        "accept-language": "en-US,en;q=0.9",
      },
      withCredentials: false,
      ...config,
    },
    request = clientSecureServer(requestConfig).catch((reason: HttpError) => {
      if (!IS_PROD) {
        console.log({ reason });
      }

      throw reason;
    });

  return {
    cancel: cancelTokenSource,
    request,
  };
};
