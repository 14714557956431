import algoliasearch from "algoliasearch";
import { sliceMetaDescription } from "@app/utilities/helpers";
import { MultipleQueriesQuery, RankingInfo } from "@algolia/client-search";
import { ALGOLIA_API_SEARCH_KEY, ALGOLIA_APP_ID } from "@app/utilities/constants";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_SEARCH_KEY);

export type ISearchResult = {
  category: string;
  categoryLabel?: string;
  subCategoryLabel?: string;
  href: string;
  label: string;
  imageUrl?: string;
  longText?: string;
  _rankingInfo?: RankingInfo;
};

export type IQueryGlobalSearchResult = {
  [category: string]: ISearchResult[];
};

export const queryGlobalSearch = async (query: string): Promise<IQueryGlobalSearchResult> => {
  const result: IQueryGlobalSearchResult = {
    Services: [],
    Teams: [],
    Blogs: [],
  };

  const queries: MultipleQueriesQuery[] = [
    {
      indexName: "Service",
      query,
      params: {
        attributesToRetrieve: ["id", "slug", "type", "typeSlug", "name", "image", "content"],
        disableTypoToleranceOnAttributes: ["experts_aoe.firstName", "experts_aoe.lastName", "experts_issues_treated.firstName", "experts_issues_treated.firstName", "content.text", "faqs"],
        getRankingInfo: true,
      },
    },
    {
      indexName: "Expert",
      query,
      params: {
        attributesToRetrieve: ["id", "slug", "firstName", "lastName", "image", "description"],
        disableTypoToleranceOnAttributes: ["description.text", "bio.text", "area_of_expertises.content.text", "issues_treated.content.text", "blogs.content.text"],
        getRankingInfo: true,
      },
    },
    {
      indexName: "Blog",
      query,
      params: {
        attributesToRetrieve: ["id", "slug", "title", "featuredImage", "content"],
        disableTypoToleranceOnAttributes: ["author.firstName", "author.lastName", "author.position", "author.credentials"],
        getRankingInfo: true,
      },
    },
    {
      indexName: "Faq",
      query,
      params: {
        attributesToRetrieve: ["id", "slug", "question", "answer"],
        disableTypoToleranceOnAttributes: [],
        getRankingInfo: true,
      },
    },
    {
      indexName: "Geolocation",
      query,
      params: {
        attributesToRetrieve: ["id", "slug", "image", "name", "content"],
        disableTypoToleranceOnAttributes: [],
        getRankingInfo: true,
      },
    },
  ];

  return searchClient
    .multipleQueries(queries)
    .then(({ results }) => {
      const [serviceRes, expertRes, blogRes, faqRes, locRes] = results as any;

      // Map services
      if (serviceRes?.hits?.length) {
        result.Services = serviceRes.hits.map((hit) => {
          const hitObj = hit as any;
          let href = hitObj?.slug ? `/services/${hitObj.slug}` : "/services";

          if (hitObj.slug && hitObj.typeSlug) {
            href = `/services/${hitObj.typeSlug}/${hitObj.slug}`;
          }

          return {
            category: "services",
            categoryLabel: "Services",
            subCategoryLabel: hitObj?.type || "",
            href,
            label: (hitObj?.name || "").trim(),
            imageUrl: hitObj.image?.src || "",
            longText: sliceMetaDescription(hitObj.content.text || "", 400),
            _rankingInfo: hitObj?._rankingInfo || {},
          };
        });
      }

      // Map experts
      if (expertRes?.hits?.length) {
        result.Teams = expertRes.hits.map((hit) => {
          const hitObj = hit as any,
            name = [hitObj?.firstName, hitObj?.lastName].filter(Boolean).join(" ").trim();

          return {
            category: "experts",
            categoryLabel: "Teams",
            subCategoryLabel: "",
            href: hitObj?.slug ? `/teams/${hitObj.slug}` : "/teams",
            label: name || "",
            imageUrl: hitObj.image?.src || "",
            longText: sliceMetaDescription(hitObj?.description?.text || "", 400),
            _rankingInfo: hitObj?._rankingInfo || {},
          };
        });
      }

      // Map blogs
      if (blogRes?.hits?.length) {
        result.Blogs = blogRes.hits.map((hit) => {
          const hitObj = hit as any,
            name = hitObj?.title?.trim();

          return {
            category: "blogs",
            categoryLabel: "Blogs",
            subCategoryLabel: "",
            href: hitObj?.slug ? `/blogs/${hitObj.slug}` : "/blogs",
            label: name || "",
            imageUrl: hitObj?.featuredImage?.src || "",
            longText: sliceMetaDescription(hitObj?.content?.text || "", 400),
            _rankingInfo: hitObj?._rankingInfo || {},
          };
        });
      }

      // Map Faqs
      if (faqRes?.hits?.length) {
        result.Faqs = faqRes.hits.map((hit) => {
          const hitObj = hit as any,
            name = hitObj?.question?.trim();

          return {
            category: "faqs",
            categoryLabel: "Faqs",
            subCategoryLabel: "",
            href: hitObj?.slug ? `/faqs#${hitObj.slug}` : "/faqs",
            label: name || "",
            imageUrl: "",
            longText: sliceMetaDescription(hitObj?.answer?.text || "", 400),
            _rankingInfo: hitObj?._rankingInfo || {},
          };
        });
      }

      // Map Geolocations
      if (locRes?.hits?.length) {
        result.Geolocations = locRes.hits.map((hit) => {
          const hitObj = hit as any,
            name = hitObj?.name?.trim();

          return {
            category: "locations",
            categoryLabel: "Locations",
            subCategoryLabel: "",
            href: hitObj?.slug ? `/locations/${hitObj.slug}` : "/locations",
            label: name || "",
            imageUrl: hitObj?.image?.src || "",
            longText: sliceMetaDescription(hitObj?.content?.text || "", 400),
            _rankingInfo: hitObj?._rankingInfo || {},
          };
        });
      }

      return result;
    })
    .catch((err) => {
      console.error(err);
      return result;
    });
};
