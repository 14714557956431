import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { IQueryGlobalSearchResult, queryGlobalSearch } from "@app/http/cms/_utils";
import styles from "./header-search-comp.module.scss";
import { GAEvents, sendEvent } from "@app/utilities/ga";
interface IHeaderSearchComp {
  className?: string;
  onSearchClosed?: () => void;
}

/**
 * Remove object keys with empty array
 * @param searchResult IQueryGlobalSearchResult
 * @returns Partial<IQueryGlobalSearchResult> for all keys that contains an array element with length > 0
 */
const filterSearchResult = (searchResult: IQueryGlobalSearchResult): [number, Partial<IQueryGlobalSearchResult>] => {
  const result: Partial<IQueryGlobalSearchResult> = {};
  let totalCount = 0;

  for (const category in searchResult) {
    if (Object.prototype.hasOwnProperty.call(searchResult, category)) {
      const searchResults = searchResult[category];

      if (searchResults?.length) {
        totalCount += searchResults.length;
        result[category] = searchResults;
      }
    }
  }

  return [totalCount, result];
};

const HeaderSearchComp: React.FC<IHeaderSearchComp> = (props: IHeaderSearchComp) => {
  const { className, onSearchClosed } = props,
    router = useRouter(),
    [isSearching, setSearching] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [searchResult, setSearchResult] = useState<Partial<IQueryGlobalSearchResult>>({}),
    [totalHits, setTotalHits] = useState(-1),
    searchTimeoutRef = useRef(0);

  const doSearch = (query: string) => {
      setSearching(true);

      if (query) {
        sendEvent(GAEvents.HEADER_SEARCH, query);
        return queryGlobalSearch(query)
          .then((res) => {
            const [totalCount, filteredResult] = filterSearchResult(res);

            setTotalHits(totalCount);
            setSearchResult(filteredResult);
          })
          .catch(() => {
            setTotalHits(0);
            setSearchResult({});
            return false;
          })
          .finally(() => {
            setSearching(false);
          });
      }

      return Promise.resolve(false);
    },
    onFormSubmitted = (evt: React.FormEvent) => {
      evt.preventDefault();

      if (searchQuery?.length) {
        const encodedSearchQuery = encodeURIComponent(searchQuery).replace("%20", "+");

        if (searchTimeoutRef.current && typeof window !== "undefined") {
          window.clearTimeout(searchTimeoutRef.current);
        }

        router.push(`/search?q=${encodedSearchQuery}`);
        onFormClose();
      }
    },
    onSearchQueryChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target?.value;
      setSearchQuery(value);

      if (value?.length && typeof window !== "undefined") {
        if (searchTimeoutRef.current) {
          window.clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = window.setTimeout(() => {
          doSearch(value);
        }, 250);
      }
    },
    onFormClose = (evt?: React.MouseEvent<HTMLButtonElement>) => {
      evt?.preventDefault();
      setTotalHits(-1);
      setSearchResult({});
      setSearchQuery("");
      if (typeof onSearchClosed === "function") {
        onSearchClosed();
      }
    };

  const classes = classNames(styles["header-search-comp"], className);

  return (
    <div className={classes}>
      <div className={styles["wrapper"]}>
        <form className={classNames(styles["form"])} onSubmit={onFormSubmitted} role="form">
          <button type="button" className="btn" role="spinbutton">
            {isSearching ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : <i className="fa fa-search"></i>}
          </button>
          <input
            type="text"
            className={styles["content-search-input"]}
            id="contentSearch"
            name="contentSearch"
            placeholder={`Search heritagecounseling.com`}
            autoComplete="off"
            autoCorrect="off"
            spellCheck={false}
            autoFocus={true}
            tabIndex={1}
            value={searchQuery}
            onChange={onSearchQueryChanged}
          />
          <button type="button" role="button" className="btn" onClick={onFormClose}>
            <i className="fa fa-times"></i>
          </button>
        </form>
        {totalHits >= 0 && (
          <aside className={styles["search-results"]}>
            {totalHits > 0 ? (
              Object.keys(searchResult).map((category) => {
                const searchElems = searchResult[category];
                return (
                  <section key={category} className={styles["search-result-section"]}>
                    <h3 className={styles["title"]}>{category}</h3>
                    <ul className={styles["list"]} role="listbox">
                      {searchElems.map((elem, idx) => (
                        <li key={idx} role="presentation">
                          <a className={styles["link"]} href={elem.href}>
                            {elem.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </section>
                );
              })
            ) : (
              <div className={styles["xy-centered"]}>
                <p> No result </p>
              </div>
            )}
          </aside>
        )}
      </div>
    </div>
  );
};

export default HeaderSearchComp;
