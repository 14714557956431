import { Expert } from "@models/Expert";
import { cmsHttp } from "../base";
import ExpertMappers from "../mappers/ExpertMappers";

export type IExpertFilterParams = {
  role?: string;
  geolocationId?: string;
  aoeServiceId?: string;
  issuesServiceId?: string;
};

export const getExperts = async (): Promise<Expert[]> => {
  return cmsHttp
    .request(
      `
        query GetExperts {
          experts(orderBy: order_ASC) {
            id
            slug
            image {
              url
              width
              height
            }
            firstName
            lastName
            officeRoles
            position
            credentials
            createdAt
            updatedAt
          }
        }
        
    `
    )
    .then((response) => {
      const { experts } = response;
      return ExpertMappers.mapExperts(experts);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getExpertsWithFilters = async (params: IExpertFilterParams): Promise<Expert[]> => {
  const { role, geolocationId, aoeServiceId, issuesServiceId } = params;
  const where = [];

  if (role?.length > 3) {
    where.push(`{officeRoles_contains_some: ${role}}`);
  }

  if (geolocationId?.length > 5) {
    where.push(`{geolocations_some: {id: "${geolocationId}"}}`);
  }

  if (aoeServiceId?.length > 5) {
    where.push(`{area_of_expertises_some: {id: "${aoeServiceId}"}}`);
  }

  if (issuesServiceId?.length > 5) {
    where.push(`{issues_treated_some: {id: "${issuesServiceId}"}}`);
  }

  let $whereQuery = "";

  if (where.length) {
    $whereQuery = `where: {AND: [${where.join(", ")}]}`;
  }

  const gqlQuery = `
    query GetExperts {
      experts(
        orderBy: order_ASC
        ${$whereQuery}
      ) {
        id
        slug
        image {
          url,
          width,
          height
        }
        geolocations {
          id,
          slug,
          name
        }
        area_of_expertises {
          id,
          name,
          slug,
          type
        }
        issues_treated {
          id,
          name,
          slug,
          type
        }
        firstName
        lastName
        officeRoles
        position
        credentials
        createdAt
        updatedAt
      }
    }
  `;

  return cmsHttp
    .request(gqlQuery)
    .then((response) => {
      const { experts } = response;
      return ExpertMappers.mapExperts(experts);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getExpertBySlug = async (slug: string): Promise<Expert> => {
  return cmsHttp
    .request(
      `
        query GetExpertBySlug($slug: String!) {
          expert(where: {slug: $slug}) {
            slug
            image {
              url
              width
              height
            }
            firstName
            lastName
            meta {
              title
              description
              noFollow
              noIndex
              ogImage {
                url (
                  transformation: {
                    image: { resize: { width: 600, height: 400, fit: clip } }
                  }
                )
              }
              twitterImage {
                url (
                  transformation: {
                    image: { resize: { width: 600, height: 400, fit: clip } }
                  }
                )
              }
            }
            bio {
              html
            }
            description {
              text
            }
            affiliations
            officeRoles
            position
            credentials
            contact {
              phone
              email
              fax
            }
            area_of_expertises {
              name
              slug
              type
            }
            issues_treated {
              name
              slug
              type
            }
            linkedinUrl
            blogs(first: 10, orderBy: totalVisits_DESC) {
              slug
              title
              featuredImage {
                url
              }
              content {
                html
                text
              }
              tags
              author {
                slug
                image {
                  url (
                    transformation: {
                      image: { resize: { height: 150, fit: clip } }
                      document: { output: { format: webp } }
                    }
                  )
                }
                firstName
                lastName
                position
              },
              totalVisits,
              createdAt,
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { expert } = response;
      return ExpertMappers.mapExpert(expert);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getExpertBySlugForIndex = async (slug: string): Promise<Expert> => {
  return cmsHttp
    .request(
      `
        query GetExpertBySlug($slug: String!) {
          expert(where: {slug: $slug}) {
            slug
            firstName
            lastName
            affiliations
            officeRoles
            position
            credentials
            linkedinUrl
            image {
              url
              width
              height
            }
            bio {
              text
            }
            description {
              text
            }
            contact {
              phone
              email
              fax
            }
            area_of_expertises {
              name
            }
            issues_treated {
              name
            }
            blogs(first: 5, orderBy: totalVisits_DESC) {
              slug
              title
              tags
              content {
                text
              }
            }
          }
        }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { expert } = response;
      return ExpertMappers.mapExpert(expert);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
