import React from "react";
import Head from "next/head";
import { META_DESCRIPTION, META_TITLE, META_TWITTER, META_URL } from "../utilities/constants";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <main className="main-container" data-testid="main-container">
      <Head>
        <title>{META_TITLE}</title>
        <meta key="title" name="title" content={META_TITLE} />
        <meta key="description" name="description" content={META_DESCRIPTION} />
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />

        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:title" property="og:title" content={META_TITLE} />
        <meta key="og:description" property="og:description" content={META_DESCRIPTION} />
        <meta key="og:url" property="og:url" content={META_URL} />
        <meta key="og:image" property="og:image" content={META_URL + "/logo.png"} />

        <meta key="twitter:card" name="twitter:card" content="summary" />
        <meta key="twitter:site" name="twitter:site" content={META_TWITTER} />
        <meta key="twitter:url" name="twitter:url" content={META_URL} />
        <meta key="twitter:title" name="twitter:title" content={META_TITLE} />
        <meta key="twitter:description" name="twitter:description" content={META_DESCRIPTION} />
        <meta key="twitter:image" name="twitter:image" content={META_URL + "/logo.png"} />
        <meta key="twitter:creator" name="twitter:creator" content={META_TWITTER} />

        <meta key="prop:title" itemProp="title" content={META_TITLE} />
        <meta key="prop:description" itemProp="description" content={META_DESCRIPTION} />
      </Head>
      {children}
    </main>
  );
};

export default Layout;
