import { createContext } from "react";
import { Geolocation } from "@models/Geolocation";
import * as CmsHttp from "@app/http/cms";

export interface ILocationContext {
  locations: Geolocation[];
}

const initialState: ILocationContext = {
  locations: [],
};

export const LocationContext = createContext<ILocationContext>(initialState);

export default function LocationProvider({ children, listLocations }: any) {
  return (
    <LocationContext.Provider
      value={{
        locations: listLocations || [],
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export const withLocations = (PageComponent: any, { ssr = true }) => {
  const WithLocation = ({ listLocations, ...props }: { listLocations: Geolocation[]; [key: string]: any }) => {
    return (
      <LocationProvider listLocations={listLocations}>
        <PageComponent {...props} />
      </LocationProvider>
    );
  };

  if (ssr || PageComponent.getInitialProps) {
    WithLocation.getInitialProps = async ({ ctx }: any) => {
      const pageProps = PageComponent.getInitialProps ? await PageComponent.getInitialProps(ctx) : {};
      const [geolocations] = await Promise.all([CmsHttp.getGeolocations()]);

      return {
        ...pageProps,
        listLocations: geolocations || [],
      };
    };
  }

  return WithLocation;
};
