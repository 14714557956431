import { CustomUri } from "@models/CustomUri";
import { cmsHttp } from "../base";
import CustomUriMappers from "../mappers/CustomUriMappers";

const CUSTOM_URI_GRAPH_BODY = `{
  uri
  meta {
    title
    description
    noFollow
    noIndex
    ogImage {
      url (
        transformation: {
          image: { resize: { width: 600, height: 400, fit: clip } }
        }
      )
    }
    twitterImage {
      url (
        transformation: {
          image: { resize: { width: 600, height: 400, fit: clip } }
        }
      )
    }
  }
  components {
    ... on CustomMarkup {
      __typename
      html
    }
    ... on RichTextComp {
      __typename
      text {
        html
      }
    }
    ... on ButtonComp {
      __typename
      href
      label
    }
    ... on ImageCarouselComp {
      __typename
      carouselItems {
        id
        caption
        photo {
          height
          width
          url (
            transformation: {
              image: { resize: { width: 1380, height: 920, fit: clip } }
            }
          )
        }
      }
    }
    ... on PhotoColumnView {
      __typename
      rtl
      image {
        width
        url
        height
      }
      content {
        ... on CustomMarkup {
          __typename
          html
        }
        ... on ButtonComp {
          __typename
          href
          label
        }
        ... on RichTextComp {
          __typename
          text {
            html
          }
        }
        ... on ImageCarouselComp {
          __typename
          carouselItems {
            id
            caption
            photo {
              height
              width
              url (
                transformation: {
                  image: { resize: { width: 1380, height: 920, fit: clip } }
                }
              )
            }
          }
        }
      }
    }
    ... on ContainerView {
      __typename
      fluid
      content {
        ... on CustomMarkup {
          __typename
          html
        }
        ... on ButtonComp {
          __typename
          href
          label
        }
        ... on RichTextComp {
          __typename
          text {
            html
          }
        }
        ... on ImageCarouselComp {
          __typename
          carouselItems {
            id
            caption
            photo {
              height
              width
              url (
                transformation: {
                  image: { resize: { width: 1380, height: 920, fit: clip } }
                }
              )
            }
          }
        }
        ... on PhotoColumnView {
          __typename
          rtl
          image {
            width
            url
            height
          }
          content {
            ... on CustomMarkup {
              __typename
              html
            }
            ... on ButtonComp {
              __typename
              href
              label
            }
            ... on RichTextComp {
              __typename
              text {
                html
              }
            }
            ... on ImageCarouselComp {
              __typename
              carouselItems {
                id
                caption
                photo {
                  height
                  width
                  url (
                    transformation: {
                      image: { resize: { width: 1380, height: 920, fit: clip } }
                    }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const getCustomUris = async (): Promise<string[]> => {
  return cmsHttp
    .request(
      `
      query getCustomUris {
        customUris {
          uri
        }
      }
    `
    )
    .then((response) => {
      const { customUris } = response;
      return (customUris || []).map((elem) => elem?.uri || "").filter(Boolean);
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
};

export const getCustomUriByUri = async (uri: string): Promise<CustomUri> => {
  return cmsHttp
    .request(
      `
      query getCustomUris($uri: String!) {
        customUri(where: {uri: $uri}) ${CUSTOM_URI_GRAPH_BODY}
      }
    `,
      {
        uri,
      }
    )
    .then((response) => {
      const { customUri } = response;
      return CustomUriMappers.mapCustomUri(customUri);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
