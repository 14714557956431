import React, { useCallback, useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useWindowScroll } from "react-use";

import { COMPANY_PHONE_NUMBER } from "@app/utilities/constants";
import SidenavToggleComp from "@components/navs/SidenavToggleComp";

import logo_full from "@assets/images/logo-full.png";
import logo from "@assets/images/logo.png";
import HeaderSearchComp from "./navs/HeaderSearchComp/HeaderSearchComp";

const Header: React.FC = () => {
  const [showSearchNav, setShowSearchNav] = useState(false),
    headerRef = useRef<HTMLElement>(),
    router = useRouter(),
    currentPath = router.asPath,
    { y: windowScrollY } = useWindowScroll(),
    [showMiniHeader, setShowMiniHeader] = useState(false),
    getNavItemClasses = useCallback(
      (path: string) => {
        return classNames("nav-item", {
          active: currentPath === path,
        });
      },
      [currentPath]
    );

  useEffect(() => {
    if (!showMiniHeader && windowScrollY > 300) {
      setShowMiniHeader(true);
    } else if (showMiniHeader && windowScrollY <= 300) {
      setShowMiniHeader(false);
    }
  }, [windowScrollY]);

  let animationTimer = 0;

  const onSearchBtnClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      if (headerRef.current) {
        const header = headerRef.current;
        header.classList.remove("searchshow", "searchopen", "searchhide");
        header.classList.add("searchshow");

        if (typeof window !== "undefined") {
          if (animationTimer) {
            window.clearTimeout(animationTimer);
            animationTimer = null;
          }

          animationTimer = window.setTimeout(() => {
            header.classList.remove("searchshow");
            header.classList.add("searchopen");
            setShowSearchNav(true);
          }, 600);
        }
      }
    },
    onSearchClosed = () => {
      if (headerRef.current) {
        const header = headerRef.current;
        header.classList.remove("searchshow", "searchopen");
        header.classList.add("searchhide");

        if (typeof window !== "undefined") {
          if (animationTimer) {
            window.clearTimeout(animationTimer);
            animationTimer = null;
          }

          animationTimer = window.setTimeout(() => {
            header.classList.remove("searchhide", "searchopen");
            setShowSearchNav(false);
          }, 600);
        }
      }
    };

  const classes = classNames("header-comp", {
    scrolled: showMiniHeader,
    searchopen: showSearchNav,
  });

  return (
    <header ref={headerRef} className={classes} data-testid="header-comp">
      <div className="container-fluid">
        <div className="wrapper">
          <div className="main-navbar">
            <nav className="navbar navbar-expand">
              <Link href="/" shallow={true}>
                <a className="navbar-brand p-0 nav-curtain">
                  <img className="logo only" src={logo.src} alt="Heritage" width={63} height={63} />
                  <img className="logo full" src={logo_full.src} alt="Heritage" width={134.5} height={63} />
                </a>
              </Link>
              <ul className="navbar-nav nav-list mx-xl-5 w-100 d-none d-lg-flex" role="navigation">
                <li className={classNames(getNavItemClasses("/"), "nav-curtain")}>
                  <Link href="/" shallow={true}>
                    <a title="Home" aria-label="Home" aria-hidden="false" className="nav-link">
                      Home
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/about"), "nav-curtain")}>
                  <Link href="/about" shallow={true}>
                    <a title="About" aria-label="About" aria-hidden="false" className="nav-link">
                      About
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/services"), "nav-curtain")}>
                  <Link href="/services" shallow={true}>
                    <a title="Services" aria-label="Services" aria-hidden="false" className="nav-link">
                      Services
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/teams"), "nav-curtain")}>
                  <Link href="/teams" shallow={true}>
                    <a title="Team" aria-label="Team" aria-hidden="false" className="nav-link">
                      Team
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/blogs"), "nav-curtain")}>
                  <Link href="/blogs" shallow={true}>
                    <a title="Blogs" aria-label="Blogs" aria-hidden="false" className="nav-link">
                      Blogs
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/faqs"), "nav-curtain")}>
                  <Link href="/faqs" shallow={true}>
                    <a title="FAQ" aria-label="FAQ" aria-hidden="false" className="nav-link">
                      FAQ
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/locations"), "nav-curtain")}>
                  <Link href="/locations" shallow={true}>
                    <a title="Locations" aria-label="Locations" aria-hidden="false" className="nav-link">
                      Locations
                    </a>
                  </Link>
                </li>
                <li className={classNames(getNavItemClasses("/careers"), "nav-curtain")}>
                  <a href="/careers" title="Careers" aria-label="Careers" aria-hidden="false" className="nav-link">
                    Careers
                  </a>
                </li>
              </ul>
            </nav>
            <nav className="navbar navbar-expand nav-curtain4 ml-auto">
              <ul className="navbar-nav mr-3 mr-xl-4">
                <li className="nav-item nav-curtain">
                  <button type="button" title="Search" aria-label="Search" role="form" className="btn btn-round btn-outline-main" onClick={onSearchBtnClicked}>
                    <i className="fa fa-search"></i>
                  </button>
                </li>
              </ul>

              <ul className="navbar-nav d-none d-lg-flex">
                <li className="nav-item nav-curtain">
                  <Link href="/portal" shallow={true}>
                    <a title="Client Portal" aria-label="Client Portal" className="btn btn-round btn-outline-main" rel="noopener noreferrer">
                      <i className="fa fa-laptop-medical"></i>
                      <span>Client Portal</span>
                    </a>
                  </Link>
                </li>
                <li className="nav-item nav-curtain">
                  <a title="Request an appointment" aria-label="Request an appointment" href={`tel:${COMPANY_PHONE_NUMBER}`} className="btn btn-round btn-main" rel="noopener noreferrer">
                    <i className="fa fa-phone-alt"></i>
                    <span>Request an appointment</span>
                  </a>
                </li>
              </ul>

              <SidenavToggleComp className="sidenav-toggle-comp nav-curtain">
                <ul className="nav d-flex flex-column text-right w-100 px-4" role="navigation">
                  <li className={getNavItemClasses("/")}>
                    <Link href="/">
                      <a aria-label="Home" aria-hidden="false" className="nav-link">
                        Home
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/about")}>
                    <Link href="/about" shallow={true}>
                      <a title="About" aria-label="About" aria-hidden="false" className="nav-link">
                        About
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/services")}>
                    <Link href="/services">
                      <a aria-label="Services" aria-hidden="false" className="nav-link">
                        Services
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/teams")}>
                    <Link href="/teams" shallow={true}>
                      <a title="Team" aria-label="Team" aria-hidden="false" className="nav-link">
                        Team
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/blogs")}>
                    <Link href="/blogs" shallow={true}>
                      <a title="Blogs" aria-label="Blogs" aria-hidden="false" className="nav-link">
                        Blogs
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/faqs")}>
                    <Link href="/faqs">
                      <a aria-label="FAQ" aria-hidden="false" className="nav-link">
                        FAQ
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/locations")}>
                    <Link href="/locations" shallow={true}>
                      <a title="Locations" aria-label="Locations" aria-hidden="false" className="nav-link">
                        Locations
                      </a>
                    </Link>
                  </li>
                  <li className={getNavItemClasses("/careers")}>
                    <a href="/careers" title="Careers" aria-label="Careers" aria-hidden="false" className="nav-link">
                      Careers
                    </a>
                  </li>
                </ul>
                <ul className="nav d-flex flex-column text-right w-100 px-4 mt-10">
                  <li className="nav-item">
                    <Link href="/portal" shallow={true}>
                      <a title="Client Portal" aria-label="Client Portal" className="btn btn-sm btn-outline-main btn-block p-3 mb-3" rel="noopener noreferrer">
                        <i className="fa fa-laptop-medical mr-3"></i>Access Patient Portal
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a title="Request an appointment" aria-label="Request an appointment" href={`tel:${COMPANY_PHONE_NUMBER}`} className="btn btn-sm btn-main btn-block p-3" rel="noopener noreferrer">
                      <i className="fa fa-phone-alt mr-3"></i>Request an appointment
                    </a>
                  </li>
                </ul>
              </SidenavToggleComp>
            </nav>

            <HeaderSearchComp className="header-search-comp" onSearchClosed={onSearchClosed} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
