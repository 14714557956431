const reBot = /((Google|Apple|bing|linkedin|duckduck|Yandex)bot|Yahoo|Baiduspider|Teoma|Slurp|google-structured-data-testing-tool)/i;

export const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

// eslint-disable-next-line
export const isNullOrUndefined = (elem: any): boolean => {
  return !!(elem === null || elem === undefined);
};

export const isBot = (userAgent?: string): boolean => {
  let uA = userAgent || "";

  if (!uA && typeof window !== "undefined") {
    uA = window?.navigator?.userAgent || "";
  }

  return reBot.test(uA);
};

export const titleCase = (str: string): string => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const sliceMetaDescription = (text: string, maxLength = 160, ellipsisText = "..."): string => {
  if (text) {
    if (text.length >= maxLength) {
      const result = [],
        words = text.trim().split(" "),
        ellipsisLength = ellipsisText.length;

      for (let i = 0, totalChars = 0, len = words.length; i < len; i++) {
        const word = words[i],
          wordLength = word.length;

        if (totalChars + wordLength < maxLength - ellipsisLength) {
          result.push(word);
          totalChars += wordLength + 1;
        } else {
          break;
        }
      }

      if (result.length) {
        return result.join(" ") + ellipsisText;
      } else {
        return text || "";
      }
    } else {
      return text;
    }
  }

  return "";
};

/**
 * Converst time in 12h into 24h format
 * @param {string} time12h Time in 12h that entails with AM/PM
 * @returns {string} time in 24h
 */
export const convertTime12to24 = (time12h: string) => {
  const modifierMatch = time12h.match(/(a|p)m/i);

  if (!modifierMatch || modifierMatch.index === -1) {
    return time12h;
  }

  const splitIdx = modifierMatch.index;
  const time = time12h.slice(0, splitIdx).trim();
  const modifier = time12h.slice(splitIdx).trim().toUpperCase();

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};
