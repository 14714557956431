import { Blog } from "@models/Blog";
import { cmsHttp } from "../base";
import BlogMappers from "../mappers/BlogMappers";

export const getBlogs = async (first = 10): Promise<Blog[]> => {
  return cmsHttp
    .request(
      `
      query GetBlogs($first: Int!) {
        blogs(first: $first, orderBy: createdAt_DESC) {
          id
          slug
          title
          featuredImage {
            url (
              transformation: {
                image: { resize: { width: 222, height: 148, fit: clip } }
                document: { output: { format: webp } }
              }
            )
            width
            height
          }
          content {
            html
            text
          }
          tags
          author {
            slug
            image {
              url (
                transformation: {
                  image: { resize: { height: 150, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
            firstName
            lastName
            position
          },
          totalVisits,
          createdAt,
          updatedAt
        }
      }
    `,
      {
        first,
      }
    )
    .then((response) => {
      const { blogs } = response;
      return BlogMappers.mapBlogs(blogs);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getPopularBlogs = async (): Promise<Blog[]> => {
  return cmsHttp
    .request(
      `
      query GetPopularBlogs {
        blogs(first: 5, orderBy: totalVisits_DESC) {
          slug
          title
          featuredImage {
            url (
              transformation: {
                image: { resize: { width: 100, fit: clip } }
                document: { output: { format: webp } }
              }
            )
            width
            height
          }
          content {
            html
            text
          }
          tags
          author {
            slug
            image {
              url (
                transformation: {
                  image: { resize: { height: 150, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
            firstName
            lastName
            position
          },
          totalVisits
          createdAt
          updatedAt
        }
      }
    `
    )
    .then((response) => {
      const { blogs } = response;
      return BlogMappers.mapBlogs(blogs);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const updateBlogVisits = async (slug: string, totalVisits: number): Promise<boolean> => {
  return cmsHttp
    .request(
      `
      mutation updateBlogVisits($slug: String!, $totalVisits: Int!) {
        updateBlog(where: {slug: $slug}, data: {totalVisits: $totalVisits}) {
          slug
          totalVisits
        }
        publishBlog(where: {slug: $slug}) {
          slug
          totalVisits
        }
      }
    `,
      {
        slug,
        totalVisits,
      }
    )
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getBlogBySlug = async (slug: string): Promise<Blog> => {
  return cmsHttp
    .request(
      `
      query GetBlogBySlug($slug: String!) {
        blog(where: {slug: $slug}) {
          slug
          title
          totalVisits
          meta {
            title
            description
            noFollow
            noIndex
            ogImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
            twitterImage {
              url (
                transformation: {
                  image: { resize: { width: 600, height: 400, fit: clip } }
                }
              )
            }
          }
          featuredImage {
            url
            width
            height
          }
          content {
            html
            text
          }
          tags
          author {
            slug
            image {
              url (
                transformation: {
                  image: { resize: { height: 150, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
            firstName
            lastName
            position
          }
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { blog } = response;
      return BlogMappers.mapBlog(blog);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getBlogBySlugForIndex = async (slug: string): Promise<Blog> => {
  return cmsHttp
    .request(
      `
      query GetBlogBySlug($slug: String!) {
        blog(where: {slug: $slug}) {
          slug
          title
          tags
          featuredImage {
            url
            width
            height
          }
          content {
            text
          }
          author {
            slug
            firstName
            lastName
            affiliations
            position
            credentials
          }
        }
      }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { blog } = response;
      return BlogMappers.mapBlog(blog);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
