import React from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import { useAmp } from "next/amp";

import { IS_PROD } from "@app/utilities/constants";
import Layout from "@app/hocs/Layout";

import Header from "@components/Header";
import AmpHeader from "@components/AmpHeader";
import Footer from "@components/Footer";
import { withLocations } from "@app/contexts/LocationContext";

import "@css/index.scss";
function App({ Component, pageProps }: AppProps): JSX.Element {
  const isAmp = useAmp();
  return (
    <>
      {isAmp && (
        <Head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
          <link rel="stylesheet" type="text/css" href="/static/styles/amp.custom.1.css" />
        </Head>
      )}
      {isAmp ? <AmpHeader /> : <Header />}
      <Layout>
        <Component {...pageProps} />
      </Layout>
      {!isAmp && <Footer />}
    </>
  );
}

export function reportWebVitals(metric) {
  if (!IS_PROD) {
    const { name, startTime, value, label } = metric;
    console.info(`[${label}]`, `[${name} ${startTime}] - ${parseInt(value)}ms`);
  }
}

export default withLocations(App, { ssr: true });
