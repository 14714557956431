import { APP_URL } from "@app/utilities/constants";
import { OrganizationSchema } from "@app/utilities/schemas";
import { Blog } from "@models/Blog";
import { format } from "date-fns";
import ExpertMappers from "./ExpertMappers";
import Utils from "./utils";

const BlogMappers = {
  mapBlog: (payload: any): Blog => {
    const blogModel: Blog = {
      id: payload?.id || "",
      slug: payload?.slug || "",
      title: payload?.title || "",
      meta: payload?.meta || null,
      featuredImage: Utils.mapCmsImage(payload?.featuredImage),
      content: Utils.mapPosh(payload?.content),
      tags: payload?.tags || [],
      author: ExpertMappers.mapExpert(payload?.author),
      totalVisits: payload?.totalVisits || 0,
      createdAt: Date.parse(payload?.createdAt) || Date.now(),
      updatedAt: Date.parse(payload?.updatedAt) || Date.now(),
    };

    /** Creates schema */
    const wordCount = blogModel.content.text.split(" ").length || 1;
    blogModel.schema = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: blogModel.title,
      image: blogModel.featuredImage.url,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": [APP_URL, "blog", blogModel.slug].join("/"),
      },
      author: blogModel.author.schema,
      wordcount: String(wordCount),
      publisher: OrganizationSchema,
      datePublished: format(blogModel.createdAt, "yyyy-MM-dd"),
      dateCreated: format(blogModel.createdAt, "yyyy-MM-dd"),
      dateModified: format(blogModel.updatedAt, "yyyy-MM-dd"),
      articleBody: blogModel.content.text,
    };

    return blogModel;
  },
  mapBlogs: (payload: any): Blog[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const blogs: Blog[] = payload.map((elem) => BlogMappers.mapBlog(elem));
      return blogs.filter((elem) => !!elem.slug);
    }

    return [];
  },
};

export default BlogMappers;
