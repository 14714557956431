import { Service } from "@models/Service";
import { cmsHttp } from "../base";
import ServiceMappers from "../mappers/ServiceMappers";
import memCache from "memory-cache";

const CACHE_TIMEOUT_MS = 86400 * 1e3; // 24 hours

export const getServices = async (): Promise<Service[]> => {
  return cmsHttp
    .request(
      `query GetServices {
          services(orderBy: order_ASC) {
            id
            slug
            type
            name
            createdAt
            updatedAt
          }
        }
        
    `
    )
    .then((response) => {
      const { services } = response;
      return ServiceMappers.mapServices(services);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const updateServiceVisits = async (slug: string, totalVisits: number): Promise<boolean> => {
  return cmsHttp
    .request(
      `
      mutation updateServiceVisits($slug: String!, $totalVisits: Int!) {
        updateService(where: {slug: $slug}, data: {totalVisits: $totalVisits}) {
          slug
          totalVisits
        }
        publishService(where: {slug: $slug}) {
          slug
          totalVisits
        }
      }
    `,
      {
        slug,
        totalVisits,
      }
    )
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getServiceBySlug = async (slug: string, memory?: boolean): Promise<Service> => {
  const key = ["getServiceBySlug", slug].join("_"),
    memModel = memCache.get(key);

  if (memory && memModel) {
    console.info("[MEMCACHE / getServiceBySlug", `Retrieved [${key}]`);
    return memModel as Service;
  }

  return cmsHttp
    .request(
      `query GetServiceBySlug($slug: String!) {
          service(where: {slug: $slug}) {
            slug
            type
            name
            image {
              url
              width
              height
            }
            content {
              html
              text
            }
            secondaryContent {
              html
              text
            }
            meta {
              title
              description
              noFollow
              noIndex
              ogImage {
                url (
                  transformation: {
                    image: { resize: { width: 600, height: 400, fit: clip } }
                  }
                )
              }
              twitterImage {
                url (
                  transformation: {
                    image: { resize: { width: 600, height: 400, fit: clip } }
                  }
                )
              }
            }
            abstract
            faqs {
              ... on Faq {
                  slug
                  question
                  featured
                  answer {
                    html
                  }
              }
            }
            blogs {
              ... on Blog {
                id
                slug
                title
                featuredImage {
                  url(
                    transformation: {image: {resize: {width: 222, height: 148, fit: clip}}, document: {output: {format: webp}}}
                  )
                  width
                  height
                }
                content {
                  html
                  text
                }
                tags
                author {
                  slug
                  image {
                    url(
                      transformation: {image: {resize: {height: 150, fit: clip}}, document: {output: {format: webp}}}
                    )
                  }
                  firstName
                  lastName
                  position
                }
                totalVisits
                createdAt
                updatedAt
              }
            }
            totalVisits
            createdAt
            updatedAt
          }
        }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { service } = response,
        result = ServiceMappers.mapService(service);

      memCache.put(key, result, CACHE_TIMEOUT_MS);
      return result;
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getServiceBySlugForIndex = async (slug: string): Promise<Service> => {
  return cmsHttp
    .request(
      `query GetServiceBySlug($slug: String!) {
          service(where: {slug: $slug}) {
            slug
            type
            name
            abstract
            experts_aoe {
              slug
              firstName
              lastName
              geolocations {
                id
                name
              }
            }
            experts_issues_treated {
              slug
              firstName
              lastName
              geolocations {
                id
                name
              }
            }
            image {
              url
              width
              height
            }
            content {
              text
            }
            secondaryContent {
              text
            }
            faqs {
              ... on Faq {
                  slug
                  question
                  featured
                  answer {
                    text
                  }
              }
            }
          }
        }
    `,
      {
        slug,
      }
    )
    .then((response) => {
      const { service } = response;
      return ServiceMappers.mapService(service);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};

export const getFrequentlyIssuesTreated = async (): Promise<Service[]> => {
  return cmsHttp
    .request(
      `
      query GetMostVisitedIssuesTreated {
        services(where: {type: Symptoms}, orderBy: totalVisits_DESC, first: 9) {
          slug
          type
          name
          abstract
          image {
            url
            width
            height
          }
          totalVisits
        }
      }
    `
    )
    .then((response) => {
      const { services } = response;
      return ServiceMappers.mapServices(services);
    })
    .catch((err) => {
      console.error({ err });
      return null;
    });
};
